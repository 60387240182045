import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

/***********************************| Services |*****************************************/
import ProdtectedRoute from "./components/route/protectedRoute";
import { getCurrentUser } from "./services/Authorization/authService";

/************************************| forms |******************************************/

import FormUsers from "./components/forms/Authorization/fr_Users";
import FormGeneralRoles from "./components/forms/Authorization/fr_generalRoles";
import ChangePassword from "./components/pages/Authorization/pg_ChangePassword";

/************************************| forms |******************************************/

// import FormCompany from "./components/forms/fr_Company";

/************************************| pages |******************************************/

import Login from "./components/pages/Authorization/pg_Login";
import Logout from "./components/pages/Authorization/esc_logout";
import PageUsers from "./components/pages/Authorization/pg_Users";
import PageGeneralRoles from "./components/pages/Authorization/pg_generalRoles";
import PageOrder from "./components/pages/Orders/pg_Orders";
import PageOrderLog from "./components/pages/Orders/pg_OrdersLog";
import PageManageOrderLog from "./components/pages/ManageOrders/pg_ManageOrdersLog";
import PagSalary from "./components/pages/EmpSalary/SalaryPage";
import PageRegistration from "./components/pages/Regestration/Registration";
import PageManageRegestration from "./components/pages/ManageRegestration/pg_ManageRegestration";

/************************************| report |******************************************/

import ReportLedger from "./components/reports/rep_Ledger";
import ReportItemsMove from "./components/reports/rep_ItemsMove";
import ReportItemInStock from "./components/reports/rep_ItemInStock";
import ReportInvoices from "./components/reports/rep_Invoices";
import ReportMunf from "./components/reports/rep_Munf";
import ReportTrialBalance from "./components/reports/rep_TrialBalance";
import ReportJournal from "./components/reports/rep_Journal";

/****************************************************************************************/

const RouteDom = () => {
  const user = getCurrentUser();
  
  return (
    <Switch>
      <ProdtectedRoute path="/users/:id" render={() => <FormUsers />} />
      <ProdtectedRoute path="/users" render={() => <PageUsers />} />
      <ProdtectedRoute
        path="/generalRoles/:id"
        render={() => <FormGeneralRoles />}
      />
      <ProdtectedRoute
        path="/generalRoles"
        render={() => <PageGeneralRoles />}
      />
      <ProdtectedRoute
        path="/changePassword"
        render={() => <ChangePassword />}
      />
      <ProdtectedRoute path="/orders" render={() => <PageOrder />} />
      <ProdtectedRoute path="/ordersLog" render={() => <PageOrderLog />} />

      <ProdtectedRoute
        path="/manageOrdersLog"
        render={() => <PageManageOrderLog />}
      />
      <ProdtectedRoute
        path="/registration"
        render={() => <PageRegistration />}
      />
      <ProdtectedRoute
        path="/manageAttsLog"
        render={() => <PageManageRegestration />}
      />

      <ProdtectedRoute path="/salary" render={() => <PagSalary />} />

      <ProdtectedRoute path="/ledger" render={() => <ReportLedger />} />
      <ProdtectedRoute
        path="/repItemsMove"
        render={() => <ReportItemsMove />}
      />
      <ProdtectedRoute
        path="/repItemInStock"
        render={() => <ReportItemInStock />}
      />
      <ProdtectedRoute path="/repInvoices" render={() => <ReportInvoices />} />
      <ProdtectedRoute path="/repMunf" render={() => <ReportMunf />} />
      <ProdtectedRoute
        path="/repTrialBalance"
        render={() => <ReportTrialBalance />}
      />
      <ProdtectedRoute path="/repJournal" render={() => <ReportJournal />} />
      {!user && (
        <Route exact path="/login">
          {" "}
          <Login />
        </Route>
      )}
      <Route exact path="/logout">
        <Logout />
      </Route>
      <Redirect
        from="/"
        exact
        to={user ? process.env.REACT_APP_PATH_NAME : "/login"}
      />
      <Redirect to={user ? "/not-found" : "/login"} />
    </Switch>
  );
};
export default RouteDom;
