import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Modal,
  Button,
  Input,
  DatePicker,
  Radio,
  Divider,
  message,
  Spin,
  Space,
} from "antd";
import { dateTimeNow, emptDate } from "../../helper/helperMethods";
import InputCurrency from "../../helper/Input/InputCurrency";
import _t from "../../../languages/translate";
import SelectSingle from "../../helper/selectSingle";
import { _titleByType, _options, _showByType, convDate } from "./healper";
import { emptString } from "../../helper/helperMethods";
import { getErrVacList } from "../../../services/ErrandVacationService";
import { getSalaryAdvList } from "../../../services/SalaryAdvTypeService";
import { editReqApp } from "../../../services/ReqAppService";
import { handleEx } from "../../helper/handleException";
import Draggable from "react-draggable";
import Media from "react-media";
const SendOrderForm = ({
  orderType,
  orderObj,
  showModal,
  setShowModal,
  onHandleData,
}) => {
  const [loadData, setLoadData] = useState(false);
  const [showField, setShowField] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [errVacOpt, setErrVacOpt] = useState([]);
  const [salaryAdvOpt, setSalaryAdvOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [form] = Form.useForm();

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  useEffect(() => {
    async function feachData() {
      try {
        if (showModal) {
          await setLoadData(true);
          if (orderType === 5 || orderType === 6) {
            const typeErrVac = orderType === 6 ? 1 : 2;
            await getErrVacByType(
              typeErrVac,
              emptString(orderObj?.errandVacationId, "")
            );
          }

          if (orderType === 7) {
            await getSalaryByType(emptString(orderObj?.salaryAdvTypeId, ""));
          }

          await setShowField(_showByType(orderType));
          if (orderObj !== undefined) {
            form.setFieldsValue({
              id: orderObj.id,
              reqDate: emptDate(orderObj.reqDate, undefined),
              timeType: orderObj.timeType,
              errandVacationId: orderObj.errandVacationId,
              salaryAdvTypeId: orderObj.salaryAdvTypeId,
              applyDate: emptDate(orderObj.applyDate, undefined),
              applyFromDate: emptDate(orderObj.applyFromDate, undefined),
              applyToDate: emptDate(orderObj.applyToDate, undefined),
              value: orderObj.value,
              note: orderObj.note,
            });
          }
          await setLoadData(false);
        }
      } catch (error) {
        handleEx(error);
      }
    }
    feachData();
  }, [showModal]);

  const getErrVacByType = async (type, addId) => {
    try {
      const { data: data } = await getErrVacList(type, addId);
      setErrVacOpt(data?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const getSalaryByType = async (addId) => {
    try {
      
      const { data: data } = await getSalaryAdvList(addId);
      
      setSalaryAdvOpt(data?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      form.resetFields();
      form.setFieldsValue({ timeType: 0 });
      setLoadData(false);
    }, 50);
  };

  const convertDateZero = (date) => {
    let _Date = new Date(date);
    let timeOffcet = _Date.getTimezoneOffset() * 60000;
    let finalDate = new Date(_Date.getTime() - timeOffcet).toISOString();
    return finalDate;
  };

  const convertDateNow = () => {
    let _Date = new Date(Date.now());
    let timeOffcet = _Date.getTimezoneOffset() * 60000;
    let finalDate = new Date(_Date.getTime() - timeOffcet).toISOString();
    return finalDate;
  };

  const onFinish = async (values) => {
    //  let values = form.getFieldsValue(true);
    try {
      await setLoadData(true);
      values.id = values.id === undefined ? "new" : values.id;
      values.reqAppType = orderType;

      if (emptDate(values.reqDate, false)) {
        values.reqDate = convertDateZero(values.reqDate, false);
      }
      if (emptDate(values.applyDate, false)) {
        values.applyDate = convertDateZero(
          convDate(values.applyDate, true),
          true
        );
      }
      if (emptDate(values.applyFromDate, false)) {
        values.applyFromDate = convertDateZero(
          convDate(values.applyFromDate, true),
          true
        );
      }
      if (emptDate(values.applyToDate, false)) {
        values.applyToDate = convertDateZero(
          convDate(values.applyToDate, true),
          true
        );
      }
      if (!showField.showTimeType) {
        delete values.timeType;
      }

      // values.reqDate = convDate(values.reqDate, false);
      // values.applyDate = convDate(values.applyDate, true);
      // values.applyFromDate = convDate(values.applyFromDate, true);
      // values.applyToDate = convDate(values.applyToDate, true);

      if (values.applyToDate < values.applyFromDate) {
        message.error(_t("strErrorDateSelected"));
        await setTimeout(async () => {
          await setLoadData(false);
        }, 500);
        return;
      }

      const { data: data } = await editReqApp(values);
      await setTimeout(async () => {
        await setLoadData(false);
      }, 500);
      if (data.code === 200) {
        setShowModal(false);
        message.success(_t("strDoneSuccessfully"));
        if (typeof onHandleData === "function") {
          onHandleData(data);
        }
      }
    } catch (error) {
      handleEx(error);
    }
  };

  return (
    <Modal
      centered
      width={800}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              <Media query="(max-width: 1024px)">{setDisabled(false)}</Media>
            }
          }}
          onMouseOut={() => (
            <Media query="(max-width: 1024px)">{setDisabled(true)}</Media>
          )}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_titleByType(orderType)}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={[]}
    >
      <Divider />
      <Spin spinning={loadData} tip={_t("strLoaging")}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            showField.showTimeType
              ? { reqDate: dateTimeNow() }
              : { timeType: 0, reqDate: dateTimeNow() }
          }
          onFinish={onFinish}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="reqDate" label={_t("strReqDate")}>
            <DatePicker size="large" bordered={false} disabled inputReadOnly/>
          </Form.Item>

          {showField.showTimeType && (
            <Form.Item name="timeType" label={_t("strType")}>
              <Radio.Group
                options={_options}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
            </Form.Item>
          )}

          {showField.showErrandVacationId && (
            <Form.Item name="errandVacationId" label={_t("strType")}>
              <SelectSingle opt={errVacOpt} size="large" />
            </Form.Item>
          )}

          {showField.showSalaryAdvTypeId && (
            <Form.Item name="salaryAdvTypeId" label={_t("strType")}>
              <SelectSingle opt={salaryAdvOpt} size="large" />
            </Form.Item>
          )}

          {showField.showApplyDate && (
            <Form.Item
              name="applyDate"
              label={_t("strDate")}
              rules={[{ required: true, message: _t("strIsRequired") }]}
            >
              <DatePicker style={{ width: "100%" }} size="large" inputReadOnly/>
            </Form.Item>
          )}
          {showField.showApplyFromDate && (
            <Form.Item
              name="applyFromDate"
              label={_t("strFrom")}
              rules={[{ required: true, message: _t("strIsRequired") }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                onChange={(e) => {
                  form.setFieldsValue({
                    applyToDate: e,
                  });
                }}
                inputReadOnly
              />
            </Form.Item>
          )}
          {showField.showApplyToDate && (
            <Form.Item
              name="applyToDate"
              label={_t("strTo")}
              rules={[{ required: true, message: _t("strIsRequired") }]}
            >
              <DatePicker style={{ width: "100%" }} size="large" inputReadOnly/>
            </Form.Item>
          )}
          {showField.showValue && (
            <Form.Item
              name="value"
              label={_t("strValue")}
              rules={[{ required: true, message: _t("strIsRequired") }]}
            >
              <InputCurrency style={{ width: "100%" }} size="large" min={1} />
            </Form.Item>
          )}

          <Form.Item name="note" label={_t("strNote")}>
            <Input.TextArea rows={4} style={{ width: "100%" }} size="large" />
          </Form.Item>
          <Divider />
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              // onClick={onFinish}
              size="large"
              disabled={loadData}
            >
              {_t("strSubmit")}
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              size="large"
              disabled={loadData}
            >
              {_t("strClose")}
            </Button>
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SendOrderForm;
