import React, { useEffect, useState, useContext } from "react";
import { Menu, message } from "antd";
import _t from "../../languages/translate";
import { Link, useLocation } from "react-router-dom";

import { getCurrUser } from "../../services/EmpService";
import { getMenu } from "../../services/menuService";
import { authFromServer } from "../../services/Authorization/authService";
import { LanguageContext } from "../../languages/Language";

import {
  FlagOutlined,
  AuditOutlined,
  DollarOutlined,
  AppstoreOutlined,
  InboxOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
let rootSubmenuKeys = [];

const SideMenu = ({ isMobile, onCloseMenu, ...rest }) => {
  const { userLanguage } = useContext(LanguageContext);
  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/order"
      : location.pathname
  );

  function handleClick(e) {
    setCurrent(e.key);
  }

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const [openKeys, setOpenKeys] = React.useState(["sub1"]);
  const [menuData, setMenuData] = useState([]);
  
  const onOpenChange = (keys) => {
    if (keys !== null) {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    }
  };

  const mainMenu = [
    {
      label: _t("stRegistration"),
      key: "/registration",
      url: "/registration",
    },
    {
      label: _t("strOrders"),
      key: "/orders",
      url: "/orders",
    },
    {
      label: _t("strOrdersLog"),
      key: "/ordersLog",
      url: "/ordersLog",
    },
    {
      label: _t("strManageOrders"),
      key: "/manageOrdersLog",
      url: "/manageOrdersLog",
    },
    {
      label: _t("strManageAtt"),
      key: "/manageAttsLog",
      url: "/manageAttsLog",
    },
    {
      label: _t("strSalary"),
      key: "/salary",
      url: "/salary",
    },
  ];

  const setIconsMenu = (e) => {
    switch (e.key) {
      case "/registration":
        e.icon = <FlagOutlined style={{ fontSize: 16 }} />;
        break;
      case "/manageOrdersLog":
        e.icon = <AuditOutlined style={{ fontSize: 16 }} />;
        break;
      case "/salary":
        e.icon = <DollarOutlined style={{ fontSize: 16 }} />;
        break;
      case "/ordersLog":
        e.icon = <InboxOutlined style={{ fontSize: 16 }} />;
        break;
      case "/orders":
        e.icon = <AppstoreOutlined style={{ fontSize: 16 }} />;
        break;
      case "/manageAttsLog":
        e.icon = <UserSwitchOutlined style={{ fontSize: 16 }} />;
        break;
      default:
        break;
    }
    return e;
  };

  const drowingMenu = (arr, currUser) => {
    let array = [];
    for (let i = 0; i < arr.length; i++) {
      if (currUser?.roleId === 1) {
        if (!currUser?.essAttLogAllow && arr[i].key === "/registration")
          continue;
        else array.push(arr[i]);
      } else {
        if ( arr[i].key !== "/manageAttsLog" && arr[i].key !== "/manageOrdersLog") {
          if (!currUser?.essAttLogAllow && arr[i].key === "/registration")
          continue;
         else array.push(arr[i]);
        }
      }
    }
    return array;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await authFromServer();
        const { data: res } = await getCurrUser();
        
        localStorage.setItem("sendLocation",res?.data?.essAttLogSetLocation)
        localStorage.setItem("attLogType",res?.data?.essAttLogType)
        setMenuData(drowingMenu(mainMenu, res?.data));
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [window.localStorage.getItem("lang")]);

  const renderMenu = (menuData) => {
    for (let i = 0; i < menuData.length; i++) {
      let _menu = setIconsMenu(menuData[i]);

      _menu.url !== null ? (
        (_menu.label = <Link to={_menu.url}>{_menu.label}</Link>)
      ) : (
        <lable> {_menu.label}</lable>
      );

      if (_menu?.children?.length > 0) {
        renderMenu(_menu?.children);
      } else {
        delete _menu?.children;
      }
    }
    return menuData;
  };

  return isMobile ? (
    <Menu
      theme="light"
      onClick={handleClick}
      items={renderMenu(menuData)}
      inlineIndent={20}
      selectedKeys={[current]}
      {...rest}
      openKeys={openKeys}
      onSelect={() => {
        onCloseMenu();
      }}
      onOpenChange={onOpenChange}
    />
  ) : (
    <Menu
      triggerSubMenuAction="click"
      onClick={handleClick}
      selectedKeys={[current]}
      theme="light"
      items={renderMenu(menuData)}
      inlineIndent={20}
      {...rest}
      mode="horizontal"
    />
  );
};

export default SideMenu;
